.tr-title {
    font-size: 20px;
    font-weight: 600;
}

.tr-tab .ant-tabs-tab-btn {
    font-size: 12px !important;
}

.tr-tab .ant-tabs-tab {
    color: rgba(0, 0, 0, 0.4) !important;
    padding: 0px !important;
    padding-bottom: 14px !important;
}

.tr-tab .ant-tabs-tab:hover, .tr-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
}

.tr-tab .ant-tabs-tab + .tr-tab .ant-tabs-tab {
    margin: 0 0 0 16px !important;
}

.tr-tab .ant-tabs-ink-bar {
    background-color: var(--primary) !important;
}

.tr-tab .ant-tabs-nav {
    margin-bottom: 0px !important;
}

.tr-card-title {
    font-weight: 600;
    font-size: 18px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tr-card-create {
    font-size: 10px;
    text-transform: uppercase;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tr-card-rate {
    font-size: 12px;
    text-transform: uppercase;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.5);
}

.tr-card-op {
    position: absolute;
    top: 10px;
    right: 6px;
    color: var(--primary);
}

.tr-button {
    color: black !important;
    transition: 0.2s;
}

.tr-button:hover {
    color: var(--primary) !important;
}

.tr-conteudo {
    max-width: 300px !important;
}


.tr-card-training {
    height: 500px;
    overflow: hidden;
}

.tr-scroll {
    height: 443px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tr-list-class {
    cursor: pointer;
    transition: 0.2s;
    border-radius: 8px;
}

.tr-list-class:hover, .tr-list-class.active {
    background-color: rgba(0, 0, 0, 0.1);
}

.tr-list-link {
    text-decoration: underline;
    transition: 0.2s;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
}

.tr-list-link:hover {
    color: var(--primary);
}

.tr-quest-title span {
    color: var(--primary);
}

.tr-quest-title {
    font-weight: 700;
}

@media (max-width: 750px) {
    .tr-row-class {
        flex-wrap: wrap !important;
    }
    .tr-class {
        width: 100% !important;
    }
    .tr-conteudo {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important
    }
}