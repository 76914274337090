.faq-content {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.faq-card {
    position: absolute;
    bottom: 85px;
    right: 25px;
    width: 0px;
    height: 0px;
    overflow: hidden;
    transition: 0.2s;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4)
}

.faq-row {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 120px;
}

.faq-button {
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.418);
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.2s;
}

.faq-button:hover {
    border: 1px solid var(--primary);
}

.faq-button .ant-typography {
    /* white-space: normal !important; */
}

.faq-card.active {
    width: 350px;
    height: 450px;
}

.faq-title {
    font-size: 18px;
    color: var(--primary);
    text-align: center;
}

.ant-image:has(.faq-img) {
    width: 40px !important;
    height: 40px !important;    
    position: absolute;
    bottom: 70px;
    right: 10px;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
}

.faq-img {
    width: 40px !important;
    height: 40px !important;
    transition: 0.2s;
}

.ant-image:has(.faq-img.active) {
    width: 80px !important;
    height: 80px !important;
}

.ant-image:has(.faq-img.active) .faq-img.active {
    width: 70px !important;
    height: 70px !important;
}

@media (min-width: 500px) {
    .ant-image:has(.faq-img) {
        bottom: 10px;
    }
    .faq-card {
        bottom: 35px;
        right: 35px;
    }
}

.onesignal-bell-container {
    display: none !important;
}