:root {
  --primary: #519937;
  --secondary: #292f44;
  --dest: #641e64;
  --hover: #57b435;
  --otherhover: #7cdf58;
  --link: #a1a1a183;
  --primary01: #508c41;
  --primary02: rgb(74,98,123);
  --primary03: rgb(217,217,217);
  --secondary01: rgb(116,161,64);
  --secondary02: rgb(214,167,24);
  --secondary03: rgb(60,125,192);
  --secondary04: rgb(95,173,223);
  --nps01: #55b03e;
  --nps02: #ffc000;
  --nps03: #f0462e;
  --nps04: #669acc;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* BUTTONS */

.btn-link.active {
  color: #519937 !important;
}
.btn-link-pc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-link-pc.active {
  color: #519937 !important;
}

.ant-btn-primary, .btn-primary, .ant-float-btn-body {
  background-color: var(--primary) !important;
}

.ant-btn-primary:hover, .btn-primary:hover, .ant-float-btn-body:hover, .ant-btn-primary:focus, .btn-primary:focus, .ant-float-btn-body:focus {
  background-color: var(--hover) !important;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn-link, .btn-link {
  color: var(--link);
}

.ant-btn-link:hover, .btn-link:hover, .ant-btn-link:focus, .btn-link:focus {
  color: var(--hover) !important;
}

.ant-btn-default, .btn-default {
  background-color: #FFF;
}

.ant-btn-default:hover, .btn-default:hover, .ant-btn-default:focus, .btn-default:focus {
  border-color: var(--hover) !important;
  color: var(--hover) !important;
}

/* LOGIN */

.login-content {
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
}

.login-card {
  width: 90%;
}

.login-card-image {
  width: 200px !important;
}

.login-card-title {
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.login-label {
  font-size: 12px;
  float: left;
  /* text-transform: uppercase; */
}

.login-label.required::after {
  content: ' *';
  color: red;
}

.login-forgot {
  color: var(--primary);
  float: right;
}

@media (min-width: 500px) {
  .login-card {
    width: 60% !important;
  }
}

@media (min-width: 900px) {
  .login-card {
    width: 30% !important;
  }
}





/* NAVBAR */

.navbar {
  background-color: #B8CD7F;
  padding: 10px;
  display: flex;
  justify-content: center;
  height: 8vh;
  box-shadow: #00000091 1px 0px 10px;
  z-index: 100 !important;
}

.ant-image:has(.navbar-image) {
  width: 44%;
}

/* MAIN */

.content {
  height: 100vh;
  overflow: hidden;
}

.main {
  background-color: #ebebeb;
  height: 85vh;
  overflow: hidden;
  overflow-y: auto;
}

.main-pc {
  background-color: #ebebeb;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

/* BOTTOM BAR */

.bottombar {
  height: 7vh;
  box-shadow: #0000004f -1px 0px 10px;
}

.bottombar .bottom {
  height: 7vh;
}

.bottombar .bottom-nav {
  display: flex;
  justify-content: center;
  align-content: center;
}

/* PAGE */

.page {
  padding: 10px;
  padding-bottom: 80px;
}

.page-user {
  padding: 10px 20px;
}

.home-title {
  color: var(--primary);
  font-size: 20px;
}

/* PROFILE */

.data-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 300px;
}


.page-content {
  padding: 20px;
}

.profile-text {
  font-size: 16px;
}

.profile-link {
  font-size: 18px;
  border-left: 2px solid var(--primary);
  padding-left: 10px;
}

.text-justify {
  text-align: justify;
  margin-bottom: 10px;
  text-indent: 20px;
}

/* SIDEBAR */
.sidebar-pc {
  height: 100vh;
  background-color: #fff;
  padding: 20px;
  box-shadow: #00000062 1px 0px 10px;
  z-index: 1 !important;
}

.sibebar-image {
  margin-bottom: 15px;
}

@media (min-width: 700px) {
  .page {
    padding: 20px 200px;
  }
  .page-user {
    padding: 20px 100px;
  }
}

@media (min-width: 750px) {
  .page {
    padding: 20px 100px !important;
  }
  .page-user {
    padding: 20px 100px !important;
  }
}

.ant-upload {
  width: 100% !important;
}

.timeline-title {
  font-size: 20px;
  font-weight: 600;
}

.timeline-text {
  text-align: justify !important;
}

.timeline-date {
  font-size: 12px;
  color: #00000077;
  text-align: right;
}

.admin-profile-upload {
  margin-top: -50px;
  margin-bottom: 20px;
}


@media (min-width: 500px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.062) !important;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background-color: #00000011;
  }
}

.addButtonPage {
  height: 80px;
  width: 80px;
  background-color: #00000023 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButtonPage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.closeButtonPage:hover, .closeButtonPage:focus {
  color: red !important;
}

.downButtonPage {
  position: absolute;
  top: 0;
  right: 30px;
  z-index: 100;
}

.downButtonPage:hover, .downButtonPage:focus {
  color: var(--hover) !important;
}

.upButtonPage {
  position: absolute;
  top: 0;
  right: 60px;
  z-index: 100;
}

.upButtonPage:hover, .upButtonPage:focus {
  color: var(--hover) !important;
}

.ant-image:has(.showImage) {
  width: 100% !important;
}

@media (max-width: 500px) {
  .addButtonPage {
    width: 70px;
    height: 70px;
  }
}

.page-builder-button {
  width: 80px;
  height: 80px;
}

.page-builder-row > span {
  position: absolute;
  bottom: -16px;
  background-color: #FFF;
  color: #00000067;
  padding: 5px;
  text-transform: uppercase;
}

.page-builder-trash {
  color: #00000067;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -36px;
}

.page-builder-row {
  padding: 20px;
  border: #0000001e 1px solid;
  border-radius: 15px;
  justify-content: space-around;
}

.page-builder-row-select {
  padding: 20px;
  border: #0000001e 1px solid;
  border-radius: 15px;
  margin-bottom: 15px;
  transition: 0.2s;
}

.page-builder-column {
  padding: 20px;
  border: #0000001e 1px solid;
  transition: 0.2s;
}

.page-builder-column-op {
  margin-top: -15px;
}

.page-builder-column:hover, .page-builder-row-select:hover {
  border-color: var(--color03-alt);
}

.addButtonPage {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-list-inline .ant-upload {
  width: 100% !important;
}

.ant-image:has(.fullwidth) {
  width: 100% !important; 
  margin-bottom: 10px;
}

.close-page-builder {
  color: #00000031 !important;
  cursor: pointer;
  transition: 0.2s;
  margin: 5px;
}

.close-page-builder:hover, .close-page-builder:focus {
  color: var(--color03) !important;
}

.blog-page-title {
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--primary);
}

.blog-page-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.blog-page-subtitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary);
}

.blog-page-paragraph {
  font-size: 14px;
  text-align: justify;
  color: var(--color07);
}

.blog-page-image {
  margin-top: 15px;
}

.ant-image:has(.blog-page-image) {
  width: 96% !important;
}

.blog-page-detail:hover {
  color: var(--color03) !important;
}

.padding-page {
  padding: 0px 80px !important;
}
.main-main {
  padding-bottom: 100px !important;
}

.card-timeline {
  background-color: #FFF;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  overflow: hidden;
}

.card-timeline-image {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

.card-timeline-text {
  padding: 10px !important;
  text-align: justify;
}

.card-timeline-more {
  padding: 10px !important;
  padding-top: 0px !important;
  color: var(--primary01);
  cursor: pointer;
}
